import { render, staticRenderFns } from "./DropdownImportStudy.vue?vue&type=template&id=2a4846de&"
import script from "./DropdownImportStudy.vue?vue&type=script&lang=js&"
export * from "./DropdownImportStudy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports